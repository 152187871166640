














































































































































































































































































import moment from 'moment';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Constants from '@/services/constants';
import { TimelineMonth } from '@/interfaces/timelineMonth';
import CountItem from '@/components/CountItem.vue';
import { AnalyticType } from '@/enums/analyticType';
import { ProductType } from '@/enums/productType';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { getLocalizedDate, stringToDate, stringToMomentDate } from '@/services/date';
import { FromTo } from '@/interfaces/fromTo';
import { Parcel } from '@/interfaces/parcel';
import { Farm } from '@/interfaces/farm';
import { TimelineSeason } from '@/interfaces/timelineSeason';

@Component({
  filters: {
    date(value: string) {
      return getLocalizedDate(value);
    }
  },
  components: {
    CountItem
  }
})
export default class Timeline extends Vue {
  @Prop() selectedAnalyticType: AnalyticType;
  @Prop() unitSummary: AnalyticSummaryItem[];
  @Prop() farmSummary: AnalyticSummaryItem[];
  @Prop() parcelsSummary: AnalyticSummaryItem[];
  @Prop() fromTo: FromTo;
  @Prop() farm: Farm;
  @Prop() analyticSummary: AnalyticSummaryItem[];
  @Prop() selectedParcels: Parcel[];
  @Prop() selectedProductType: ProductType;
  @Prop() monthSelectorEnabled: boolean;
  @Prop() calendarEnabled: boolean;
  @Prop() isUnSelectSingleDayAllowed: boolean;
  @Prop() isSplitMode: boolean;
  @Prop() isSeasonMode: boolean;

  private months: TimelineMonth[] = [];
  private seasons: TimelineSeason[] = [];
  public controlDate: moment.Moment = null;
  private selectedMonth = null;
  private selectedSeason = null;
  private selectedSeasonMonth: TimelineMonth = null;
  productType = ProductType;
  DATE_FORMAT_LOCALIZED = Constants.DATE_FORMAT_LOCALIZED;

  @Watch('fromTo')
  onFromToChanged(fromTo: FromTo): void {
    this.controlDate = fromTo ? stringToMomentDate(fromTo.to) : null;
  }

  @Watch('unitSummary')
  onUnitSummaryChanged(): void {
    this.selectedMonth = null;
    this.selectedSeason = null;
    this.moveToLastAnalytic();
  }

  @Watch('farmSummary')
  onFarmSummaryChanged(): void {
    this.moveToLastAnalytic();
  }

  @Watch('parcelsSummary')
  onParcelsSummaryChanged(): void {
    this.updatePeriods();
  }

  @Watch('selectedAnalyticType')
  onSelectedAnalyticTypeChanged(): void {
    this.moveToLastAnalytic();
  }

  mounted(): void {
    this.moveToLastAnalytic();
  }

  moveToLastAnalytic(): void {
    this.selectedMonth = null;
    this.selectedSeason = null;
    this.selectedSeasonMonth = null;
    const analyticSummaryItems = this.getSortedAnalyticSummaryItems();
    if (analyticSummaryItems.length) {
      let analyticSummaryItem = analyticSummaryItems[analyticSummaryItems.length - 1] as AnalyticSummaryItem;
      if (this.$store.state.preSelectedSurvey) {
        const analyticSummaryItemOnSurveyDate = analyticSummaryItems.find(
          ({ Date }) => this.$store.state.preSelectedSurvey.Date === Date
        );
        if (analyticSummaryItemOnSurveyDate) {
          analyticSummaryItem = analyticSummaryItemOnSurveyDate;
        }
      }
      if (this.isSeasonMode) {
        this.$emit('changeFromTo', {
          from: analyticSummaryItem.MomentDate.clone().startOf('month').format(Constants.DATE_FORMAT),
          to: analyticSummaryItem.MomentDate.clone().endOf('month').format(Constants.DATE_FORMAT)
        });
        this.selectedSeasonMonth = {
          year: analyticSummaryItem.MomentDate.year(),
          index: analyticSummaryItem.MomentDate.month()
        } as TimelineMonth;
      } else if (this.isSatAnalyticType) {
        this.$emit('changeFromTo', {
          from: analyticSummaryItem.Date,
          to: analyticSummaryItem.Date
        });
      } else if (this.$store.state.analytic.selectedAnalyticType === 'vegetative-index') {
        if (this.$store.state.unitSummary.length !== 0) {
          const vegetativeIndexDates = this.unitSummary
            .filter((analyticSummaryItem: AnalyticSummaryItem) => {
              return analyticSummaryItem.Analytic === this.selectedAnalyticType;
            })
            .sort((a: AnalyticSummaryItem, b: AnalyticSummaryItem) => a.MomentDate.valueOf() - b.MomentDate.valueOf());
          this.$emit('changeFromTo', {
            from: vegetativeIndexDates[vegetativeIndexDates.length - 1]['Date'],
            to: vegetativeIndexDates[vegetativeIndexDates.length - 1]['Date']
          });
        }
      } else {
        this.$emit('changeFromTo', {
          from: analyticSummaryItem.MomentDate.clone()
            .startOf('months')
            .subtract(11, 'months')
            .format(Constants.DATE_FORMAT),
          to: analyticSummaryItem.MomentDate.clone().endOf('months').format(Constants.DATE_FORMAT)
        });
      }
      this.setPeriods(analyticSummaryItem.MomentDate);
    } else if (this.selectedProductType === ProductType.FIELD_INFO) {
      this.$emit('changeFromTo', {
        from: moment().format(Constants.DATE_FORMAT),
        to: moment().format(Constants.DATE_FORMAT)
      });
      this.setPeriods(moment());
    } else {
      const to = moment().endOf('month');
      const from = to.clone().startOf('month').subtract(11, 'months');
      this.$emit('changeFromTo', {
        from: from.format(Constants.DATE_FORMAT),
        to: to.format(Constants.DATE_FORMAT)
      });
      this.setPeriods(to);
    }
    this.updatePeriods();
  }

  private get isSatAnalyticType(): boolean {
    return this.selectedAnalyticType && this.selectedAnalyticType.startsWith('sat-');
  }

  getSelectedMonthMenuDays(month: TimelineMonth): number[] {
    if (this.isSelectedSingleDay) {
      const from = stringToDate(this.fromTo.from);
      if (from.getFullYear() === month.year && from.getMonth() === month.index) {
        return [from.getDate()];
      }
    }
    return [];
  }

  setPeriods(date: moment.Moment): void {
    if (this.isSeasonMode) {
      this.setSeasons(date.clone().startOf('year').subtract(2, 'years'));
    } else {
      this.setMonths(date.clone().startOf('month').subtract(11, 'months'));
    }
  }

  setMonths(from: moment.Moment): void {
    const months: TimelineMonth[] = [];
    for (let i = 0; i < 12; i++) {
      const date = from.clone();
      date.add(i, 'months');
      months.push({
        index: date.month(),
        year: date.year(),
        surveysCount: 0,
        days: [],
        commonDays: null
      });
    }
    this.months = months;
    this.$emit('changeTimelineFromTo', {
      from: from.startOf('months').format(Constants.DATE_FORMAT),
      to: from.startOf('months').add(11, 'months').endOf('month').format(Constants.DATE_FORMAT)
    });
  }

  setSeasons(from: moment.Moment): void {
    const seasons: TimelineSeason[] = [];
    for (let i = 0; i < 3; i++) {
      const date = from.clone();
      date.add(i, 'years');
      seasons.push({
        index: date.year(),
        year: date.year(),
        surveysCount: 0,
        months: [],
        commonDays: null
      });
    }
    this.seasons = seasons;
    this.$emit('changeTimelineFromTo', {
      from: from.startOf('months').format(Constants.DATE_FORMAT),
      to: from.startOf('months').add(11, 'months').endOf('month').format(Constants.DATE_FORMAT)
    });
  }

  selectMonth(month: TimelineMonth): void {
    if (!this.monthSelectorEnabled) {
      return;
    }

    let from = moment(new Date(this.months[0].year, this.months[0].index, 1));
    let to = from.clone().add(1, 'years').subtract(1, 'days');
    if (this.selectedMonth !== month.index) {
      from = moment(new Date(month.year, month.index, 1));
      to = from.clone().endOf('month');
      this.selectedMonth = month.index;
    } else {
      this.selectedMonth = null;
    }

    this.$emit('changeFromTo', {
      from: from.format(Constants.DATE_FORMAT),
      to: to.format(Constants.DATE_FORMAT)
    });
  }

  selectSeason(season: TimelineSeason): void {
    if (season.months.length === 1) {
      this.onSelectMonthFromTimeline(season, season.months[0].index);
    }
  }

  private get availableDates(): string[] {
    const analyticSummaryItems = this.getSortedAnalyticSummaryItems();
    return Array.from(
      new Set(analyticSummaryItems.map((analyticSummaryItem: AnalyticSummaryItem) => analyticSummaryItem.Date))
    );
  }

  private updatePeriods(): void {
    if (this.isSeasonMode) {
      this.updateSeasons();
    } else {
      this.updateMonths();
    }
  }

  private updateSeasons(): void {
    const analyticSummaryItems = this.analyticSummary.filter((analyticSummaryItem: AnalyticSummaryItem) => {
      return analyticSummaryItem.Analytic === this.selectedAnalyticType;
    });

    const monthsByYear = {};
    this.seasons.forEach((season: TimelineSeason) => {
      monthsByYear[season.year] = Array(12).fill(null);
    });
    analyticSummaryItems.forEach((analyticSummaryItem: AnalyticSummaryItem) => {
      const months = monthsByYear[analyticSummaryItem.MomentDate.year()];
      if (months) {
        const month = analyticSummaryItem.MomentDate.month();
        if (months[month] === null) {
          months[month] = {};
        }
        if (!months[month][analyticSummaryItem.Date]) {
          months[month][analyticSummaryItem.Date] = [];
        }
        months[month][analyticSummaryItem.Date].push(analyticSummaryItem.ParcelId);
      }
    });
    this.seasons.forEach((season: TimelineSeason) => {
      const months = monthsByYear[season.year];
      const surveyDates = months.reduce((arr, month) => (month ? [...arr, ...Object.keys(month)] : arr), []);
      season.months = [];
      months.forEach((data, index) => {
        if (data) {
          season.months.push({
            index: index,
            year: season.year,
            surveysCount: Object.keys(data).length
          } as TimelineMonth);
        }
      });
      season.surveysCount = surveyDates.length;
      season.commonDays = null;
    });
  }

  private updateMonths(): void {
    const analyticSummaryItems = this.analyticSummary.filter((analyticSummaryItem: AnalyticSummaryItem) => {
      return analyticSummaryItem.Analytic === this.selectedAnalyticType;
    });

    const monthsByYear = {
      [this.months[0].year]: Array(12).fill(null),
      [this.months[11].year]: Array(12).fill(null)
    };
    analyticSummaryItems.forEach((analyticSummaryItem: AnalyticSummaryItem) => {
      const months = monthsByYear[analyticSummaryItem.MomentDate.year()];
      if (months) {
        const month = analyticSummaryItem.MomentDate.month();
        if (months[month] === null) {
          months[month] = {};
        }
        if (!months[month][analyticSummaryItem.Date]) {
          months[month][analyticSummaryItem.Date] = [];
        }
        months[month][analyticSummaryItem.Date].push(analyticSummaryItem.ParcelId);
      }
    });
    this.months.forEach((month: TimelineMonth) => {
      const months = monthsByYear[month.year];
      const monthData = (months && months[month.index]) || {};
      const surveyDates = Object.keys(monthData);
      month.days = surveyDates.map((date) => stringToDate(date).getDate()).sort((a, b) => (a > b ? 1 : -1));
      month.surveysCount = surveyDates.length;
      month.commonDays = null;

      if (this.selectedParcels.length) {
        const commonDays = [];
        surveyDates.forEach((date) => {
          const parcelIds = monthData[date] as string[];
          if (
            parcelIds.length >= this.selectedParcels.length &&
            this.selectedParcels.every((p: Parcel) => parcelIds.includes(p.id))
          ) {
            commonDays.push(date);
          }
        });
        month.commonDays = commonDays;
      }
    });
  }

  get selectedDate(): moment.Moment {
    return stringToMomentDate(this.fromTo.to);
  }

  get isSelectedSingleDay(): boolean {
    return stringToMomentDate(this.fromTo.from).isSame(this.selectedDate);
  }

  get isGoToNextMonthAvailable(): boolean {
    if (this.months[11]) {
      return moment(new Date(this.months[11].year, this.months[11].index, 1)).add(1, 'months').isSameOrBefore(moment());
    }
    return true;
  }

  gotoNextSeason(): void {
    const from = moment(new Date(this.seasons[0].year, 0, 1)).add(1, 'year');
    this.setSeasons(from);
    this.updateSeasons();
  }

  gotoNextMonth(): void {
    const firstMonth = moment(new Date(this.months[0].year, this.months[0].index, 1));
    const from = firstMonth.add(1, 'months');
    const to = from.clone().add(1, 'years').subtract(1, 'days');

    let fromStr = from.format(Constants.DATE_FORMAT);
    let toStr = to.format(Constants.DATE_FORMAT);
    if (this.isSelectedSingleDay) {
      const nearestDate = this.getNearestDate(this.selectedDate.clone().add(1, 'months'), from, to, false);
      if (nearestDate) {
        fromStr = nearestDate.format(Constants.DATE_FORMAT);
        toStr = fromStr;
      }
    }
    this.$emit('changeFromTo', {
      from: fromStr,
      to: toStr
    });
    this.selectedMonth = null;
    this.setMonths(from);
    this.updateMonths();
  }

  gotoPrevSeason(): void {
    const from = moment(new Date(this.seasons[0].year, 0, 1)).subtract(1, 'year');
    this.setSeasons(from);
    this.updateSeasons();
  }

  gotoPrevMonth(): void {
    const firstMonth = moment(new Date(this.months[0].year, this.months[0].index, 1));
    const from = firstMonth.subtract(1, 'months');
    const to = from.clone().add(1, 'years').subtract(1, 'days');
    let fromStr = from.format(Constants.DATE_FORMAT);
    let toStr = to.format(Constants.DATE_FORMAT);
    if (this.isSelectedSingleDay) {
      const nearestDate = this.getNearestDate(this.selectedDate.clone().subtract(1, 'months'), from, to, true);
      if (nearestDate) {
        fromStr = nearestDate.format(Constants.DATE_FORMAT);
        toStr = fromStr;
      }
    }

    this.$emit('changeFromTo', {
      from: fromStr,
      to: toStr
    });
    this.selectedMonth = null;
    this.setMonths(from);
    this.updateMonths();
  }

  private getNearestDate(
    onDate: moment.Moment,
    fromDate: moment.Moment,
    toDate: moment.Moment,
    isDirectionFuture = false
  ): moment.Moment {
    let nearestDate = null;
    this.availableDates.forEach((date: string) => {
      const mDate = stringToMomentDate(date);
      if (mDate.isSameOrAfter(fromDate) && mDate.isSameOrBefore(toDate)) {
        if (isDirectionFuture) {
          if (mDate.isSameOrAfter(onDate) && (!nearestDate || mDate.isBefore(nearestDate))) {
            nearestDate = mDate;
          }
        } else {
          if (mDate.isSameOrBefore(onDate) && (!nearestDate || mDate.isAfter(nearestDate))) {
            nearestDate = mDate;
          }
        }
      }
    });
    return nearestDate;
  }

  gotoPrev12Month(): void {
    const firstMonth = moment(new Date(this.months[0].year, this.months[0].index, 1));
    const from = firstMonth.subtract(12, 'months');
    const to = from.clone().add(1, 'years').subtract(1, 'days');
    let fromStr = from.format(Constants.DATE_FORMAT);
    let toStr = to.format(Constants.DATE_FORMAT);
    if (this.isSelectedSingleDay) {
      const nearestDate = this.getNearestDate(this.selectedDate.clone().subtract(12, 'months'), from, to, true);
      if (nearestDate) {
        fromStr = nearestDate.format(Constants.DATE_FORMAT);
        toStr = fromStr;
      }
    }

    this.$emit('changeFromTo', {
      from: fromStr,
      to: toStr
    });
    this.selectedMonth = null;
    this.setMonths(from);
    this.updateMonths();
  }

  gotoNext12Month(): void {
    const firstMonth = moment(new Date(this.months[0].year, this.months[0].index, 1));
    let from = firstMonth.add(12, 'months');
    let to = from.clone().add(1, 'years').subtract(1, 'days');

    const lastToday = moment().endOf('months');
    if (lastToday.isBefore(to)) {
      to = lastToday;
      from = to.clone().startOf('months').subtract(11, 'months');

      const items = this.getSortedAnalyticSummaryItems();
      if (items.length) {
        const last = items[items.length - 1];
        if (last.MomentDate.isAfter(from) && last.MomentDate.isBefore(to)) {
          to = last.MomentDate.clone().endOf('months');
          from = to.clone().startOf('months').subtract(11, 'months');
        }
      }
    }

    let fromStr = from.format(Constants.DATE_FORMAT);
    let toStr = to.format(Constants.DATE_FORMAT);
    if (this.isSelectedSingleDay) {
      const nearestDate = this.getNearestDate(this.selectedDate.clone().add(12, 'months'), from, to, false);
      if (nearestDate) {
        fromStr = nearestDate.format(Constants.DATE_FORMAT);
        toStr = fromStr;
      }
    }

    this.$emit('changeFromTo', {
      from: fromStr,
      to: toStr
    });
    this.selectedMonth = null;
    this.setMonths(from);
    this.updateMonths();
  }

  private getSortedAnalyticSummaryItems(): AnalyticSummaryItem[] {
    return (this.farm ? this.farmSummary : this.unitSummary)
      .filter((analyticSummaryItem: AnalyticSummaryItem) => {
        return analyticSummaryItem.Analytic === this.selectedAnalyticType;
      })
      .sort((a: AnalyticSummaryItem, b: AnalyticSummaryItem) => a.MomentDate.valueOf() - b.MomentDate.valueOf());
  }

  isWarningIconVisible(month: TimelineMonth): boolean {
    if (this.isSatAnalyticType) {
      const items = this.getSortedAnalyticSummaryItems();
      if (items.length) {
        const first = items[0];
        const date = moment(new Date(month.year, month.index, 1)).endOf('month');
        if (date.isAfter(first.MomentDate) && month.surveysCount === 0) {
          return true;
        }
      }
    }
    return false;
  }

  getMonthName(month: number): string {
    return moment().month(month).format('MMM');
  }

  isCalendarDateDisabled(date: moment.Moment): boolean {
    return date.isAfter(moment());
  }

  isMonthDisabled(month: TimelineMonth): boolean {
    const momentMonth = moment(new Date(month.year, month.index, 1));
    const isNoSurveys = this.monthSelectorEnabled ? false : month.surveysCount === 0;
    return momentMonth.isAfter(moment()) || isNoSurveys;
  }

  isSeasonDisabled(season: TimelineSeason): boolean {
    const momentMonth = moment(new Date(season.year, 0, 1));
    const isNoSurveys = this.monthSelectorEnabled ? false : season.surveysCount === 0;
    return momentMonth.isAfter(moment()) || isNoSurveys;
  }

  private selectAllMonths(): void {
    const from = moment(new Date(this.months[0].year, this.months[0].index, 1));
    const to = from.clone().add(1, 'years').subtract(1, 'days');

    this.$emit('changeFromTo', {
      from: from.format(Constants.DATE_FORMAT),
      to: to.format(Constants.DATE_FORMAT)
    });
  }

  unSelectDate(): void {
    if (this.isUnSelectSingleDayAllowed) {
      this.selectAllMonths();
    }
  }

  onSelectMonthFromTimeline(season: TimelineSeason, monthIndex: number): void {
    const month = season.months.find((m: TimelineMonth) => m.index === monthIndex);
    const from = moment(new Date(month.year, month.index, 1));
    const to = from.clone().endOf('month');
    this.selectedSeasonMonth = month;
    this.selectedSeason = null;

    this.$emit('changeFromTo', {
      from: from.format(Constants.DATE_FORMAT),
      to: to.format(Constants.DATE_FORMAT)
    });
  }

  onSelectDateFromTimeline(month: TimelineMonth, day: number): void {
    const date = moment(new Date(month.year, month.index, day));
    if (this.isSelectedSingleDay && date.format(Constants.DATE_FORMAT) === this.fromTo.from) {
      this.unSelectDate();
    } else {
      this.onDateChange(date);
    }
  }

  onDateChange(date: moment.Moment): void {
    const from = date || stringToMomentDate(this.fromTo.from).startOf('year');

    if (this.isSelectedSingleDay && from.format(Constants.DATE_FORMAT) === this.fromTo.from) {
      this.selectAllMonths();
      return;
    }

    const to = date || from.clone().endOf('year');

    this.$emit('changeFromTo', {
      from: from.format(Constants.DATE_FORMAT),
      to: to.format(Constants.DATE_FORMAT)
    });
    this.selectedMonth = null;
    const firstMonth = moment(new Date(this.months[0].year, this.months[0].index, 1));
    const lastMonth = moment(new Date(this.months[11].year, this.months[11].index, 1)).add(1, 'month');
    if (from.isBefore(firstMonth) || from.isAfter(lastMonth)) {
      this.setMonths(from.startOf('month').subtract(11, 'month'));
      this.updateMonths();
    }
  }

  gotoNextDay(): void {
    let nextDate: moment.Moment = null;
    this.availableDates.forEach((date: string) => {
      const mDate = stringToMomentDate(date);
      if (mDate.isAfter(this.selectedDate) && (!nextDate || mDate.isBefore(nextDate))) {
        nextDate = mDate;
      }
    });
    if (nextDate) {
      this.onDateChange(nextDate);
    }
  }

  gotoPrevDay(): void {
    let prevDate: moment.Moment = null;
    this.availableDates.forEach((date: string) => {
      const mDate = stringToMomentDate(date);
      if (mDate.isBefore(this.selectedDate) && (!prevDate || mDate.isAfter(prevDate))) {
        prevDate = mDate;
      }
    });
    if (prevDate) {
      this.onDateChange(prevDate);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCurrentStyle(date: moment.Moment): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {};
    if (this.availableDates.includes(date.format(Constants.DATE_FORMAT))) {
      style.border = '1px solid  #009a32';
      style.borderRadius = '50%';
    }
    return style;
  }

  getYearLegendWidthPx(months: TimelineMonth): string {
    const oneMonthWidth = this.isSplitMode ? 49 : 63;
    return oneMonthWidth * (12 - months[0].index) + 5 + 'px';
  }
}
